/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import 'aos/dist/aos.css'
import 'react-toastify/dist/ReactToastify.css'
import '../styles/font.scss'
import '../styles/globals.css'
import '../styles/Main.module.scss'
import '../utils/axios'
import 'react-datepicker/dist/react-datepicker.css'
import 'styles/datepicker.css'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import AOS from 'aos'
import axios from 'axios'
import DynamicMeta, { IDynamicMetaProps } from 'components/DynamicMeta'
import { ConnectedRouter } from 'connected-next-router'
import { LIKELION_EDU_CAREER } from 'features/klass/consts'
import _ from 'lodash'
import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'
import { useRouter } from 'next/router'
import qs from 'qs'
import { useCallback, useEffect, useMemo } from 'react'
import TagManager from 'react-gtm-module'
import { useSelector, useStore } from 'react-redux'
import { ToastContainer } from 'react-toastify'
// @ts-ignore
import { PersistGate } from 'redux-persist/integration/react'
import AuthProvider from 'shared/contexts/Auth'
import { GclidQueryParamsProvider } from 'shared/contexts/GclidQueryParams'
import PlayerProvider from 'shared/contexts/Player'
import { UtmAndGclidQueryParamsProvider } from 'shared/contexts/UtmAndGclidQueryParams'
import { RootState, wrapper } from 'stores'
import BebridgeAIChatbot from 'utils/bebridgeChatbot'
import ChannelTalk, { ICSSettings } from 'utils/channeltalk'
import { detectBrowser, detectDeviceType, detectOSName } from 'utils/detectUserAgent'
import { appWithTranslation } from 'utils/i18n'

export interface IAppProps extends AppProps {
  config: IPublicConfig | null
}

interface IMeta {
  [key: string]: {
    keyword: string
    title: string
  }
}

const WrapperApp = ({ Component, pageProps, config }: IAppProps): JSX.Element => {
  const store = useStore()
  const router = useRouter()
  const { profile, termsOfService } = useSelector((state: RootState) => state.auth)
  const { sections, kdtAttr } = useSelector((state: RootState) => state.courses)
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  const dynamicMetaProps: IDynamicMetaProps | undefined = useMemo(() => {
    if (router.pathname === '/') {
      return {
        title: '홈',
        ogTitle: '멋쟁이사자처럼',
      }
    }
    if (router.pathname === '/event/webinar') {
      return {
        title: '멋쟁이사자처럼 웨비나',
        ogTitle: '멋쟁이사자처럼 웨비나',
        subtitle: '2023년 10월 27일 금요일 14시, LIKELION WEBINAR',
        ogImage: {
          property: 'og:image',
          content: '/img/webinar_og_image.png',
        },
        ogImageSecure: {
          property: 'og:image:secure_url',
          content: '/img/webinar_og_image.png',
        },
        url: `${config?.baseUrl as string}/event/webinar`,
      }
    }
    if (router.pathname === '/event/epson') {
      return {
        title: 'Epson Innovation Challenge',
        ogTitle: 'Epson Innovation Challenge',
        subtitle: '2천만원 상금, API 활용 비즈니스 솔루션 도전!',
        ogSubtitle: '2천만원 상금, API 활용 비즈니스 솔루션 도전!',
        ogImage: {
          property: 'og:image',
          content: '/img/og_epson.png',
        },
        ogImageSecure: {
          property: 'og:image:secure_url',
          content: '/img/og_epson.png',
        },
        url: `${config?.baseUrl as string}/event/epson`,
      }
    }

    if (router.pathname === '/event') {
      return {
        title: '이벤트',
        ogTitle: '멋사 이벤트',
        subtitle:
          '멋쟁이사자처럼의 기업연계 프로젝트, 해커톤, 콘퍼런스! 다양한 이벤트로 멋쟁이사자처럼을 경험해보세요. 성장하는 IT 인재를 위한 인사이트 특강부터 디자이너와 개발자를 위한 콘퍼런스까지. 지금 확인해보세요.',
        ogSubtitle: '다양한 이벤트로 멋쟁이사자처럼을 경험해보세요',
        keywords:
          '멋쟁이사자처럼 해커톤, 멋쟁이사자처럼 콘퍼런스, 멋쟁이사자처럼 특강, 멋사 특강, 멋사 이벤트, 멋사 해커톤, 멋사 콘퍼런스',
        ogImage: {
          property: 'og:image',
          content: '/img/og_event.png',
        },
        ogImageSecure: {
          property: 'og:image:secure_url',
          content: '/img/og_event.png',
        },
        url: `${config?.baseUrl as string}/event`,
      }
    }

    if (router.pathname === '/event/univ') {
      return {
        title: '멋대 친구 추천 이벤트',
        ogTitle: '멋대 친구 추천 이벤트',
        subtitle: '친구에게 온보딩 트랙 추천하면 100% 선물!',
        ogImage: {
          property: 'og:image',
          content: '/img/univ_ogImage.png',
        },
        ogImageSecure: {
          property: 'og:image:secure_url',
          content: '/img/univ_ogImage.png',
        },
        url: `${config?.baseUrl as string}/event/univ`,
      }
    }

    if (router.pathname === '/event/ddcon') {
      return {
        title: 'DDC 2023 / LIKELION',
        ogTitle: 'DDC 2023 / LIKELION',
        subtitle: '새해 첫 성장 콘퍼런스, DDC 2023',
        ogImage: {
          property: 'og:image',
          content: '/img/ddcon/ddc_ogImage.png',
        },
        ogImageSecure: {
          property: 'og:image:secure_url',
          content: '/img/ddcon/ddc_ogImage.png',
        },
        url: `${config?.baseUrl as string}/event/ddcon`,
      }
    }

    if (router.pathname === '/pre-notice') {
      return {
        title: '국비지원 부트캠프 사전알림',
        ogTitle: '멋사 국비지원 부트캠프 사전알림',
      }
    }

    if (router.pathname === '/enterprise') {
      return {
        title: '기업 문의',
        ogTitle: '멋쟁이사자처럼 기업 문의',
        subtitle: `내일의 세상을 만드는 LIKELION BUSINESS. ${LIKELION_EDU_CAREER}년 동안 쌓아 온 탄탄한 IT 교육 노하우를 바탕으로 기획부터 사후관리까지 모든 것을 책임집니다.`,
        ogSubtitle: '내일의 세상을 만드는 LIKELION BUSINESS. ',
        keywords:
          '기업 교육, 기업 해커톤, 멋쟁이사자처럼 기업 교육, 기업 맞춤형 프로그램, DX 교육, 멋사 DX, 멋사 기업 교육',
        ogImage: {
          property: 'og:image',
          content: '/img/og_hackathon.png',
        },
        ogImageSecure: {
          property: 'og:image:secure_url',
          content: '/img/og_hackathon.png',
        },
        url: `${config?.baseUrl as string}/enterprise`,
      }
    }

    if (router.pathname === '/school') {
      return {
        title: '국비지원 부트캠프',
        ogTitle: '멋쟁이사자처럼 국비지원 부트캠프',
        subtitle: `교육비 100% 지원. 끝까지 완주 가능한 IT 부트캠프. 모집 중인 멋쟁이사자처럼 국비지원 부트캠프를 확인해보세요. 주 5일 전일제 라이브 교육. 넘볼 수 없는 ${LIKELION_EDU_CAREER}년 교육 노하우. 압도적인 수료율이 증명하는 차별화된 케어 시스템. 강하고 끈끈한 멋사 네트워크.`,
        ogSubtitle: '끝까지 완주 가능한 IT 국비지원 부트캠프.',
        keywords:
          'KDT 멋쟁이사자처럼 국비지원, 멋쟁이사자처럼, KDT, 커리어, IT 교육, LIKELION, K digital training, 부트캠프, 코딩, 국비지원 교육, 멋쟁이사자처럼 부트캠프, 멋사, 멋쟁이사자처럼 후기, 멋사 국비지원 부트캠프, 내일배움카드, 멋사 프론트엔드 부트캠프, 멋사 백엔드 Python 부트캠프, 멋사 백엔드 Java 부트캠프, 멋사 AI 웹 서비스 개발 부트캠프, 멋사 데이터 분석 부트캠프, 멋사 UX/UI 디지인 부트캠프, 멋사 유니티 게임 개발 부트캠프, 멋사 Android 개발 부트캠프, 멋사 iOS 개발 부트캠프, 멋사 클라우드 엔지니어링 부트캠프, 멋사 프론트엔드 심화 부트캠프, 멋사 백엔드 심화 부트캠프',
        ogImage: {
          property: 'og:image',
          content: '/img/og_kdt.png',
        },
        ogImageSecure: {
          property: 'og:image:secure_url',
          content: '/img/og_kdt.png',
        },
        url: `${config?.baseUrl as string}/school`,
      }
    }

    if (router.pathname === '/onboarding') {
      return {
        title: '6주완성',
        ogTitle: '멋쟁이사자처럼 6주완성',
        subtitle:
          'IT 분야 지식이 없어도 누구나 수강 가능한 기초 강의입니다. 내일배움카드만 있으면 모든 강의 90% 할인. 하루 30분 투자로 수료까지. 시간/장소 제약 없이 효율적인 수강이 가능해요.',
        ogSubtitle: 'IT 분야 지식이 없어도 누구나 수강 가능한 기초 강의입니다.',
        keywords:
          '국민내일배움카드, 기초 IT 강의, 내일배움카드, 국비지원 교육, IT 역량, 디지털 역량, 멋쟁이사자처럼 6주완성, 프론트엔드 6주완성, 백엔드(Java) 6주완성, 백엔드(Python) 6주완성, 데이터분석 6주완성, 블록체인 6주완성, 멋쟁이사자처럼 기초 IT 교육, 멋사 6주완성',
        ogImage: {
          property: 'og:image',
          content: '/img/og_kdc.png',
        },
        ogImageSecure: {
          property: 'og:image:secure_url',
          content: '/img/og_kdc.png',
        },
        url: `${config?.baseUrl as string}/onboarding`,
      }
    }

    if (router.pathname === '/onboarding/[schoolId]' && router.query.schoolId) {
      // eslint-disable-next-line no-useless-escape
      const klass = (router.query.schoolId as string).replace(/^kdc-|\-.+/g, '')
      if (router.query.schoolId) {
        const kdcKlassId: IMeta = {
          frontend: { keyword: '프론트엔드', title: '프론트엔드 6주완성' },
          backendj: { keyword: '백엔드 자바', title: '백엔드(Java) 6주완성' },
          backendp: { keyword: '백엔드 파이썬', title: '백엔드(Python) 6주완성' },
          blockchain: { keyword: '블록체인', title: '블록체인 6주완성' },
          datadata: { keyword: '데이터분석', title: '데이터분석 6주완성' },
        }

        return {
          title: kdcKlassId[klass]?.title,
          ogTitle: kdcKlassId[klass]?.title,
          subtitle:
            'IT 분야 지식이 없어도 누구나 수강 가능한 기초 강의입니다. 내일배움카드만 있으면 모든 강의 90% 할인. 하루 30분 투자로 수료까지. 시간/장소 제약 없이 효율적인 수강이 가능해요.',
          ogSubtitle: 'IT 분야 지식이 없어도 누구나 수강 가능한 기초 강의입니다.',
          keywords:
            '국민내일배움카드, 기초 IT 강의, 내일배움카드, 국비지원 교육, IT 역량, 디지털 역량, 멋쟁이사자처럼 6주완성, 프론트엔드 6주완성, 백엔드(Java) 6주완성, 백엔드(Python) 6주완성, 데이터분석 6주완성, 블록체인 6주완성, 멋쟁이사자처럼 기초 IT 교육, 멋사 6주완성',
          ogImage: {
            property: 'og:image',
            content: `/img/onboarding/${router.query?.schoolId as string}.png`,
          },
          ogImageSecure: {
            property: 'og:image:secure_url',
            content: `/img/onboarding/${router.query?.schoolId as string}.png`,
          },
          url: `https://likelion.net/onboarding/${router.query?.schoolId as string}`,
        }
      }
    }

    if (kdtAttr && router.pathname === '/school/[schoolId]' && router.query.schoolId) {
      return {
        title: kdtAttr.title,
        ogTitle: kdtAttr.title,
        subtitle: `${kdtAttr.subtitle as string} ${kdtAttr.panelText as string}`,
        ogSubtitle: kdtAttr.subtitle as string,
        keywords: `${(kdtAttr.keywords as string) || ''}`,
        ogImage: {
          property: 'og:image',
          content: kdtAttr.ogImage,
        },
        ogImageSecure: {
          property: 'og:image:secure_url',
          content: kdtAttr.ogImage,
        },
        url: `https://likelion.net/school/${kdtAttr.id}`,
      }
    }

    if (kdtAttr && router.pathname === '/startup-station/[schoolId]' && router.query.schoolId) {
      return {
        title: kdtAttr.title,
        ogTitle: kdtAttr.title,
        subtitle: `${kdtAttr.subtitle as string} ${kdtAttr.panelText as string}`,
        ogSubtitle: kdtAttr.subtitle as string,
        keywords: `${(kdtAttr.keywords as string) || ''}`,
        ogImage: {
          property: 'og:image',
          content: kdtAttr.ogImage,
        },
        ogImageSecure: {
          property: 'og:image:secure_url',
          content: kdtAttr.ogImage,
        },
        url: `https://likelion.net/startup-station/${kdtAttr.id}`,
      }
    }

    if (sections && router.query.id)
      return {
        title: sections.title,
        ogTitle: sections.title,
        subtitle: sections.subtitle,
        ogImage: {
          property: 'og:image',
          content: sections.ogImage,
        },
        ogImageSecure: {
          property: 'og:image:secure_url',
          content: sections.ogImage,
        },
        url: `https://likelion.net/courses/technology/${sections.id}`,
      }

    if (kdtAttr && router.query.schoolId) {
      const isApplyPage = _.chain(router.pathname).split('/').last().isEqual('apply').value()
      const isApplyCompletePage = _.chain(router.pathname)
        .split('/')
        .last()
        .isEqual('complete')
        .value()
      const title =
        (isApplyPage && `${kdtAttr.title} 지원`) ||
        (isApplyCompletePage && `${kdtAttr.title} 지원완료`) ||
        '멋쟁이사자처럼'
      return {
        title: title,
        ogTitle: title,
        subtitle: kdtAttr.subtitle,
        ogImage: {
          property: 'og:image',
          content: kdtAttr.ogImage,
        },
        ogImageSecure: {
          property: 'og:image:secure_url',
          content: kdtAttr.ogImage,
        },
        url: `https://likelion.net/school/${kdtAttr.id}`,
      }
    }
  }, [sections, kdtAttr, router])

  useEffect(() => {
    AOS.init()
  }, [])

  useEffect(() => {
    if (!config) return
    TagManager.initialize({ gtmId: 'GTM-TLB7GRH' })
    if (typeof window.nodeEnv === 'undefined') window.nodeEnv = config.nodeEnv
    if (typeof window.baseUrl === 'undefined') window.baseUrl = config.baseUrl
    if (typeof window.apiEndpoint === 'undefined') window.apiEndpoint = config.apiEndpoint
    if (typeof window.bitmovinPlayerKey === 'undefined')
      window.bitmovinPlayerKey = config.bitmovinPlayerKey
    if (typeof window.bitmovinAnalyticsKey === 'undefined')
      window.bitmovinAnalyticsKey = config.bitmovinAnalyticsKey
    if (typeof window.oauthRegisterEndpoint === 'undefined')
      window.oauthRegisterEndpoint = config.oauthRegisterEndpoint
    if (typeof window.oauthLogoutEndpoint === 'undefined')
      window.oauthLogoutEndpoint = config.oauthLogoutEndpoint
    if (typeof window.channelTalkKey === 'undefined') window.channelTalkKey = config.channelTalkKey
    if (typeof window.s3PresignedUrl === 'undefined') window.s3PresignedUrl = config.s3PresignedUrl
    if (typeof window.s3BucketName === 'undefined') window.s3BucketName = config.s3BucketName
    if (typeof window.kakaoShare === 'undefined') window.kakaoShare = config.kakaoShare

    if (typeof window.signInUrl === 'undefined') {
      const params = qs.stringify({
        response_type: 'code',
        client_id: config.oauthClientId,
        redirect_uri: config.oauthRedirectUrl,
        scope: config.oauthScope,
      })
      window.signInUrl = `${config.oauthAuthorizationEndpoint}?${params}`
    }
  }, [config, router])

  const getVersion = useCallback(async () => {
    const {
      data: { figlet },
    } = await axios.get('/api/version')
    window.getVersion = () => {
      console.log(figlet)
      return 'HACK YOUR LIFE'
    }
  }, [])

  useEffect(() => {
    void getVersion()
  }, [getVersion])

  useEffect(() => {
    if (profile !== null) {
      TagManager.dataLayer({ dataLayer: { user_id: profile.id } })
    }
  }, [profile])

  // 비브릿지
  useEffect(() => {
    if (profile !== null) {
      const { id } = profile
      const initializeChatbot = async () => {
        const instance = await BebridgeAIChatbot(String(id))

        if (instance) {
          const relevantPaths = [
            '/my/courses/detail/[schoolId]/board',
            '/my/courses/detail/[schoolId]/attendance',
            '/my/courses/detail/[schoolId]/curriculum',
            '/my/courses/detail/[schoolId]/notice',
            '/my/courses/detail/[schoolId]/notice/[noticeId]',
            '/my/courses/detail/[schoolId]/note',
            '/my/courses/detail/[schoolId]/qna',
            '/my/courses/detail/[schoolId]/qna/[qnaId]',
            '/my/courses/detail/[schoolId]/quiz',
            '/my/courses/detail/[schoolId]/quiz/[quizId]',
            '/my/courses/detail/[schoolId]/project',
            '/my/courses/detail/[schoolId]/consulting',
            '/my/courses/detail/[schoolId]/consulting/[consultId]',
            '/my/courses/detail/[schoolId]/survey',
            '/my/courses/detail/[schoolId]/job',
          ]
          instance.closeChat()
          if (relevantPaths.includes(router.pathname)) {
            instance.showChatButton()
          } else {
            instance.hideChatButton()
          }
        }
      }
      void initializeChatbot()
    }
  }, [profile, router.pathname])

  useEffect(() => {
    const channelTalk = new ChannelTalk()
    if (!window.ChannelIO) {
      channelTalk.loadScript()
    }

    const settings: ICSSettings = {
      pluginKey: window.channelTalkKey,
      createdAt: new Date(),
      web: {
        browserName: detectBrowser(navigator),
        osName: detectOSName(navigator),
        device: detectDeviceType(navigator),
      },
    }

    if (profile !== null && termsOfService !== null) {
      const { id, name, email, phoneNo: mobileNumber } = profile
      const { sms, email: marketingAgreementEmail } = termsOfService.marketingAgreement
      settings.memberId = `${id}`
      settings.profile = {
        name,
        email,
        mobileNumber,
        referrer: router.asPath,
      }
      settings.unsubscribeEmail = marketingAgreementEmail
      settings.unsubscribeTexting = sms
    }

    channelTalk.boot(settings)
    channelTalk.setpage(window.location.href)
    if (
      [
        '/courses/technology/[id]/[chapterId]/[resourceId]',
        '/courses/technology/[id]/[chapterId]/[resourceId]/[attrId]',
        '/school/[schoolId]/apply',
        '/onboarding/[schoolId]/apply/identity-verification',
        '/onboarding/[schoolId]/apply/card',
        '/onboarding/[schoolId]/apply/card/no-card',
        '/onboarding/[schoolId]/apply/enrollment',
        '/onboarding/[schoolId]/apply/payment',
        '/startup-station/[schoolId]/apply',
        // 강의보드 숨김처리
        '/my/courses/detail/[schoolId]/board',
        '/my/courses/detail/[schoolId]/attendance',
        '/my/courses/detail/[schoolId]/curriculum',
        '/my/courses/detail/[schoolId]/notice',
        '/my/courses/detail/[schoolId]/notice/[noticeId]',
        '/my/courses/detail/[schoolId]/note',
        '/my/courses/detail/[schoolId]/qna',
        '/my/courses/detail/[schoolId]/qna/[qnaId]',
        '/my/courses/detail/[schoolId]/quiz',
        '/my/courses/detail/[schoolId]/quiz/[quizId]',
        '/my/courses/detail/[schoolId]/project',
        '/my/courses/detail/[schoolId]/consulting',
        '/my/courses/detail/[schoolId]/consulting/[consultId]',
        '/my/courses/detail/[schoolId]/survey',
        '/my/courses/detail/[schoolId]/job',
      ].includes(router.pathname)
    ) {
      channelTalk.hide()
    } else {
      channelTalk.show()
    }
    if (['/onboarding'].includes(router.pathname)) {
      settings.zIndex = 250
    }

    return () => {
      channelTalk.shutdown()
    }
  }, [profile, router.asPath, router.pathname, termsOfService])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('tokenError', (e: any) => {
        if (e.detail.error) {
          void router.push('/oauth/logout')
        }
      })
    }
  }, [router])

  return (
    <>
      <DynamicMeta {...dynamicMetaProps} />
      <PersistGate
        // @ts-ignore
        persistor={store.__persistor}
        loading={<div className="preloader" style={{ opacity: 0.494764 }}></div>}>
        <UtmAndGclidQueryParamsProvider>
          <GclidQueryParamsProvider>
            <AuthProvider>
              <PlayerProvider>
                <ConnectedRouter>
                  <ToastContainer
                    position="top-center"
                    icon={false}
                    draggable={false}
                    autoClose={3500}
                    hideProgressBar
                  />
                  <QueryClientProvider client={queryClient}>
                    <Component {...pageProps} />
                    <ReactQueryDevtools initialIsOpen={config?.nodeEnv === 'development'} />
                  </QueryClientProvider>
                </ConnectedRouter>
              </PlayerProvider>
            </AuthProvider>
          </GclidQueryParamsProvider>
        </UtmAndGclidQueryParamsProvider>
      </PersistGate>
    </>
  )
}

WrapperApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  let config = null

  if (typeof window === 'undefined') {
    const allConfig: IConfig = require('../utils/config').default
    config = {
      nodeEnv: allConfig.nodeEnv,
      baseUrl: allConfig.baseUrl,
      oauthAuthorizationEndpoint: allConfig.oauthAuthorizationEndpoint,
      oauthClientId: allConfig.oauthClientId,
      oauthScope: allConfig.oauthScope,
      oauthRegisterEndpoint: allConfig.oauthRegisterEndpoint,
      oauthLogoutEndpoint: allConfig.oauthLogoutEndpoint,
      apiEndpoint: allConfig.apiEndpoint,
      oauthRedirectUrl: allConfig.oauthRedirectUrl,
      bitmovinPlayerKey: allConfig.bitmovinPlayerKey,
      bitmovinAnalyticsKey: allConfig.bitmovinAnalyticsKey,
      channelTalkKey: allConfig.channelTalkKey,
      s3PresignedUrl: allConfig.s3PresignedUrl,
      s3BucketName: allConfig.s3BucketName,
      kakaoShare: allConfig.kakaoShare,
    }
  }

  return { ...appProps, config }
}

export default wrapper.withRedux(appWithTranslation(WrapperApp))
